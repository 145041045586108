import React from "react";
import { connect } from 'react-redux';
import  avatar_default from  "../../assetss/img/logo_aliens.png"
import Swal  from 'sweetalert2';

function UserOptions(props){

    const handleLogout = () => {

        Swal.fire({
            icon: 'question',
            html: '<p style="font-size:16px"><b>Cerrar Sesion</b><br>¿Seguro desea salir?</p>',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: '<span style="font-size:16px"><strong>Aceptar</strong></span>',
            cancelButtonText: '<span style="font-size:16px"><strong>Cancelar</strong></span>',
            width: 350,
            height:250
        }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.clear();
                    window.location.href = '/';
                }
        })
    }
    
    return(
        <React.Fragment>
           <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img height="22" className="rounded-circle" src={avatar_default} />
                    <span className="text-white p-1">{props.perfil} - {props.nickname}</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-perfil dropdown-menu-dark">
                    {props.perfil == 'ADMIN' ? <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalAjustedeUsuario"><i className="bi bi-people"></i> Usuarios</a></li>:''}
                    {props.perfil == 'ADMIN' ? <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalCrearUsuario"><i className="bi bi-person-add"></i> Crear usuario</a></li>:''}
                    <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalCambioDeContrasena"><i className="bi bi-key"></i> Cambiar Contraseña</a></li>
                    <li><a className="dropdown-item" href="#" onClick={handleLogout}><i className="bi bi-power"></i> Cerrar Sesion</a></li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default connect(null, null)(UserOptions)