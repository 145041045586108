import InstaceAxios  from "./Api";

export const dataClientes = async (id_cliente = null) => {

    const result = await InstaceAxios.get('cliente/clientes?id_cliente='+id_cliente)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crear_cliente = async (data) => {
    const result = await InstaceAxios.post('cliente/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const actualizar_cliente = async (data) => {
    const result = await InstaceAxios.post('cliente/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const tipo_activo = async () => {
    const result = await InstaceAxios.get(`cliente/tipo_activo`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const bancos = async () => {
    const result = await InstaceAxios.get(`cliente/bancos`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const municipios = async () => {
    const result = await InstaceAxios.get(`cliente/municipios`)
        .then(res => res)
        .catch(error => error)
    return result
}