import InstaceAxios  from "./Api";

export const habilitar_saldo_disponible = async (data) => {
    const result = await InstaceAxios.post('ajustes/habilitar_saldo_disponible', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const traslado_saldo_intereses = async (data) => {
    const result = await InstaceAxios.post('ajustes/traslado_saldo_intereses', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const retirar_saldo = async (data) => {
    const result = await InstaceAxios.post('ajustes/retirar_saldo', data)
        .then(res => res)
        .catch(error => error)
    return result
}