import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import '../../assetss/css/Sidebar.css'
import Saldos from "./Saldos";
import UserOptions from "./UserOptions";
import logospace from "../../assetss/img/logospacewhite.png"
import { useNavigate } from 'react-router-dom';
import ModalCodigoCredito from "../Reportes/ModalCodigoCredito";
import ModalPagar from "../Creditos/ModalPagar";
import ModalRegistrarPago from "../Creditos/ModalRegistrarPago";
import { get_usuario }  from "../../services/Login";
import { setUsuario } from "../../actions";
import CambiarContrasena from "../usuario/cambiarContrasena";
import AjustedeUsuario from "../usuario/AjustedeUsuario";
import CrearUsuario from "../usuario/CrearUsuario";
import Editarusuario from "../usuario/Editarusuario";


function Sidebar(props){

    useEffect( () =>{
        if(token === null){
            navigate('/');
        }else{
            handleConsultarUsuario();
        }
    },[]);

    const handleConsultarUsuario = async () => {

        try {
            await get_usuario().then(response => {

                if(response.status === 200 && response.data.status_message == 'OK'){
                    setUsuario(response.data.data);
                    props.setUsuario(response.data.data);
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            //setMsg('Error al cargar la información del crédito', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }
    
    const [usuario, setUsuario] = useState({});
    const token = localStorage.getItem("token")
    const navigate = useNavigate();

    return(
        <React.Fragment>
            <Navbar collapseOnSelect expand="xl" bg="primary" data-bs-theme="dark" variant="primary" className="pe-3 ps-3">
                <Navbar.Brand>                    
                    <Link to="/inicio" className={props.modulo == 'inicio' ? 'nav-link text-white' : 'nav-link' }>
                        <img width="45" className="" src={logospace} /> 
                        <span className="mx-2 text-white" style={{fontSize:'22px'}}>{usuario.empresa}</span>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <ul className="navbar-nav justify-content-end flex-grow-1 ul-menu">
                        <li className="nav-item ps-2 fs-5">
                            <Link to="/inicio" className={props.modulo == '/inicio' ? 'nav-link text-white' : 'nav-link' }>
                                    <span><b>Inicio</b></span>
                            </Link>
                        </li>
                        <li className="nav-item ps-2 fs-5">
                            <Link to="/clientes"  className={props.modulo == '/clientes' ? 'nav-link text-white' : 'nav-link' }>
                                <span><b>Prospectos</b></span>
                            </Link>
                        </li>
                        <li className="nav-item ps-2 fs-5">
                            <Link to="/creditos"  className={props.modulo == '/creditos' ? 'nav-link text-white' : 'nav-link' }>
                                <span><b>Créditos</b></span>
                            </Link>
                        </li>
                        <li className="nav-item ps-2 fs-5">
                            <Link to="/simulacion"  className={props.modulo == '/simulacion' ? 'nav-link text-white' : 'nav-link' }>
                                <span><b>Simulación</b></span>
                            </Link>
                        </li>
                        {/*<li className="nav-item ps-2 fs-5">
                            <Link to="/ajustes"  className={props.modulo == '/ajustes' ? 'nav-link text-white' : 'nav-link' }>
                                <span><b>Ajustes</b></span>
                            </Link>
                        </li>*/}
                        <li className="nav-item dropdown ps-2 fs-5">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <b className={props.modulo == '/extracto' || props.modulo == '/credito' ? 'text-white' : '' }>Reportes</b>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <Link to="/extracto"  className='dropdown-item'>
                                        <span>Extracto</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link  className='dropdown-item' data-bs-toggle="modal" data-bs-target="#ModalCodigoCredito">
                                        <span>Crédito</span>
                                    </Link>
                                </li>
                                
                                {/*<li><hr className="dropdown-divider"></hr></li>*/}
                        
                            </ul>
                        </li>
                    </ul>
                </Nav>
                <Nav>
                    {/*<Saldos></Saldos>*/}
                </Nav>
                <Nav>
                    <UserOptions nickname={usuario.nickname} perfil={usuario.perfil}/>
                </Nav>
                </Navbar.Collapse>
            </Navbar>
            <ModalCodigoCredito></ModalCodigoCredito>
            <ModalPagar></ModalPagar>
            <ModalRegistrarPago></ModalRegistrarPago>
            <CambiarContrasena></CambiarContrasena>
            <AjustedeUsuario></AjustedeUsuario>
            <CrearUsuario></CrearUsuario>
            <Editarusuario></Editarusuario>
        </React.Fragment>
    )
}

const mapDispatchToProps  = {
    setUsuario
};

export default connect(null, mapDispatchToProps)(Sidebar)